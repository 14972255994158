<template>
  <auth-layout>
    <router-view />
  </auth-layout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout'

export default {
  components: { AuthLayout },

  mounted() {
    this.setAuthToken()
  },

  methods: {
    setAuthToken() {
      const token = this.$route.query.access_token
      if (token) {
        this.$store.commit('auth/setToken', {
          token_type: 'bearer',
          access_token: token,
        })
        this.$router.replace({ name: 'home' })
      }
    },
  },
}
</script>
