<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card max-width="380" class="mx-auto">
        <v-layout align-center justify-center column class="pt-6">
          <v-img :src="logo" width="144px" height="144px"></v-img>
          <div class="display-1 ma-3">{{ appName }}</div>
          <div class="subtitle-1">Sign into your account</div>
        </v-layout>
        <div class="pa-6">
          <v-tabs v-model="tab" class="no-tab-bar">
            <v-tabs-items v-model="tab">
              <v-tab-item value="username-tab">
                <v-form ref="form" @submit.prevent="checkUsername">
                  <v-text-field
                    autofocus
                    outlined
                    clearable
                    required
                    v-model="form.username"
                    label="Email or phone number"
                    :error-messages="form.$getError('username')"
                  ></v-text-field>
                  <v-btn
                    block
                    large
                    color="primary"
                    type="submit"
                    :loading="form.$busy"
                    >Submit</v-btn
                  >
                  <template v-if="ssoIsEnable">
                    <v-btn class="mt-6 sso-button" @click="openSsoLink">
                      <img
                        src="@/assets/icons/microsoft-icon.svg"
                        class="mr-2"
                        alt
                      />
                      <span>Sign in with Microsoft</span>
                    </v-btn>
                  </template>
                </v-form>
              </v-tab-item>
              <v-tab-item value="password-tab">
                <div class="text-center mb-8">
                  <div v-if="form.username">
                    <v-chip close @click:close="notYou" outlined>
                      <v-avatar left>
                        <img
                          :src="require('@/assets/images/default-profile.png')"
                          alt=""
                        />
                      </v-avatar>
                      {{ form.username }}
                    </v-chip>
                  </div>
                </div>
                <v-form ref="form" @submit.prevent="signIn">
                  <v-text-field
                    autofocus
                    outlined
                    required
                    class="mb-5"
                    hide-details="auto"
                    type="password"
                    label="Password"
                    v-model="form.password"
                    :error-messages="form.$getError('password')"
                  ></v-text-field>
                  <v-btn
                    block
                    large
                    color="primary"
                    type="submit"
                    :loading="form.$busy"
                    >Sign in</v-btn
                  >
                </v-form>
                <v-layout class="mt-4">
                  <v-btn
                    text
                    color="primary"
                    block
                    :to="{ name: 'auth.forgot-password' }"
                    >Forgot password ?</v-btn
                  >
                </v-layout>
                <template v-if="ssoIsEnable">
                  <v-btn class="mt-6 sso-button" @click="openSsoLink">
                    <img src="@/assets/icons/microsoft-icon.svg" alt />
                    <span>Sign in with Microsoft</span>
                  </v-btn>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'

export default {
  name: 'LoginPage',
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  computed: {
    ...mapGetters({
      appName: 'appName',
      logo: 'logo',
      domainName: 'domainName',
    }),

    ...mapState({
      sso: (state) => state.ssoProvider.ssoDetail,
    }),

    ssoIsEnable() {
      return this.sso ? this.sso.is_enable : false
    },
  },

  data() {
    return {
      tab: 'username-tab',
      form: new Form({
        username: '',
        password: '',
      }),
    }
  },

  mounted() {
    this.checkSsoExist()
  },

  methods: {
    ...mapActions({
      checkSso: 'ssoProvider/checkSsoEnable',
    }),

    async checkSsoExist() {
      await this.checkSso().catch((err) => {
        this.showSnackbar(this.getErrorMessage(err), false)
      })
    },

    checkUsername() {
      this.form.$clearErrors()
      this.form.$busy = true
      this.$api
        .post('auth/check-username', { username: this.form.username })
        .then(({ data }) => {
          this.form.$busy = false
          this.showPasswordTab()
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 404) {
            this.form.$setErrors({ username: err.response.data.message })
          } else if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    signIn() {
      this.form.$clearErrors()
      this.form.$busy = true
      this.$api
        .post('auth/admin/login', {
          username: this.form.username,
          password: this.form.password,
        })
        .then(({ data }) => {
          this.form.$busy = false
          this.$store.commit('auth/setToken', data.data)
          this.$router.replace({ name: 'home' })
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 401) {
            this.form.$setErrors({ password: err.response.data.message })
          } else if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    notYou() {
      this.clearForm()
    },

    showUsernameTab() {
      this.tab = 'username-tab'
    },

    showPasswordTab() {
      this.tab = 'password-tab'
    },

    clearForm() {
      this.form.$reset()
      this.showUsernameTab()
    },

    openSsoLink() {
      if (this.sso) {
        window.location.href = this.sso.signin_url
      }
    },
  },
  watch: {
    'form.username'() {
      this.form.$clearError('username')
    },
    'form.password'() {
      this.form.$clearError('password')
    },
  },
}
</script>

<style lang="scss" scoped>
.sso-button {
  border: 1px solid black;
  border-radius: 0;
  color: #5e5e5e;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 2.563rem;
  margin: auto;
  max-width: 13.438rem;
  padding: 0.625rem;
  text-align: center;

  img {
    margin-top: 0.125rem;
  }
}
</style>
